<template>
  <div id="companyEditContainer" class="relative">

    <div>

      <div class="sm:none md:flex-auto lg:flex xl:flex">

        <div class="sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
          <div class="sm:mr-0 md:mr-0 lg:mr-5 xl:mr-10">

            <div class="vx-row mb-2" v-if="companyData">
              <div class="vx-col w-full">
                <h1 v-if="companyData && companyData.id">Uredi podjetje</h1>
                <h1 v-if="!companyData.id">Dodaj podjetje</h1>
              </div>
            </div>


            <div class="vx-card mt-5 pb-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Uradni podatki podjetja</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">

                  <div class="grid grid-cols-1">
                    <vs-input class="w-full" label="Uradni naziv podjetja"
                              v-model="companyData.company_name"/>
                  </div>

                  <div class="grid grid-cols-1 mt-5">
                    <vs-input class="w-full" label="Prikazno ime podjetja"
                              v-model="companyData.company_display_name"/>
                  </div>

                  <div class="grid grid-cols-2 gap-5 mt-3 mt-5">
                    <vs-input class="w-full" label="Matična številka podjetja"
                              v-model="companyData.donor_id_number"/>

                    <vs-input class="w-full" label="Davčna številka"
                              v-model="companyData.company_vat"/>
                  </div>

                  <div class="grid grid-cols-1 mt-5">
                    <vs-input class="w-full" label="Številka transakcijskega računa"
                              v-model="companyData.donor_bank_account"/>
                  </div>

                </div>
              </div>
            </div>

            <div class="vx-card mt-5 pb-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Naslov podjetja</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">
                  <div class="grid grid-cols-1">
                    <label for="companyAddress" class="vs-input--label">Naslov podjetja</label>

                    <gmap-autocomplete
                      ref="companyAddress"
                      id="companyAddress"
                      @place_changed="(data) => { getAddressData(data, 'company') }"
                      placeholder="začni vpisovati, nato izberi naslov iz ponujenih možnosti"
                      :value="companyData.address.quick_search"
                      :options="{componentRestrictions: { country: 'si' }}"
                      :select-first-on-enter="true"
                      class="vs-inputx vs-input--input normal"></gmap-autocomplete>
                  </div>

                  <div class="collapseBody">

                    <div class="flex mt-5">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Ulica" v-model="companyData.address.route"/>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Hišna številka"
                                  v-model="companyData.address.street_number"/>
                      </div>
                    </div>
                    <div class="flex mt-5">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Naziv pošte" v-model="companyData.address.postal_town"/>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Poštna številka"
                                  v-model="companyData.address.postal_code"/>
                      </div>
                    </div>
                    <div class="flex mt-5">
                      <div class="w-3/4">
                        <vs-input class="w-full" label="Občina" v-model="companyData.address.municipality"/>
                      </div>
                      <div class="w-1/4 ml-5">
                        <vs-input class="w-full" label="Država" v-model="companyData.address.country"/>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>


            <div class="vx-card mt-5 pb-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Kontaktni podatki</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">

                  <div class="grid grid-cols-1">
                    <vs-input class="w-full" label="Kontaktna oseba"
                              v-model="companyData.contact_person"/>
                  </div>

                  <div class="grid grid-cols-2 gap-5 mt-3 mt-5">
                    <vs-input class="w-full" label="Telefonska številka kontaktne osebe"
                              v-model="companyData.contact_person_phone"/>

                    <vs-input class="w-full" label="Email kontaktne osebe"
                              v-model="companyData.contact_person_email"/>
                  </div>

                </div>
              </div>
            </div>


            <div class="vx-card mt-5 pb-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Podatki o donaciji</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">

                  <div class="vx-col w-full" v-if="donorTypes">
                    <label for="donorType" class="vs-input--label">Tip donatorja</label>
                    <v-select id="donorType" :options="donorTypes" class=""
                              v-model="donorTypeId" placeholder="Tip donatorja"
                              :reduce="item => item.id"
                              label="name">

                      <template v-slot:option="option">
                        <vs-list-item
                          :title="option.name">
                          <template slot="avatar">
                            <vs-avatar size="large" :text="option.name" class="mr-5"/>
                          </template>
                        </vs-list-item>
                      </template>

                      <template v-slot:selected-option="option">
                        <vs-list-item
                          :title="option.name">
                          <template slot="avatar">
                            <vs-avatar size="large" :text="option.name" class="mr-5"/>
                          </template>
                        </vs-list-item>
                      </template>

                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                      </template>
                    </v-select>

                  </div>

                  <div class="grid grid-cols-1 mt-5">
                    <vs-input class="w-full" label="Vrednost donacije"
                              v-model="companyData.donation_value"/>
                  </div>

                  <div class="grid grid-cols-1 mt-5">
                      <div class="w-full">
                        <label class="vs-input--label">Opis donacije</label>
                        <vs-textarea class="w-full" rows="6" v-model="companyData.donation_description" />
                      </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="vx-card mt-5 pb-5">
              <div class="vx-card__header">
                <div class="vx-card__title">
                  <h4 class="">Podatki o pogodbenem sodelovanju</h4>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body">

                  <div class="grid grid-cols-3 gap-5">
                    <div>
                      <label class="vs-input--label">Datum pošiljanja pogodbe</label>
                      <datepicker placeholder="Datum pošiljanja pogodbe" :language="slSI" :format="customDateFormatter"
                                  v-model="companyData.contract_sent_date"></datepicker>
                    </div>

                    <div>
                      <label class="vs-input--label">Datum podpisa pogodbe</label>
                      <datepicker placeholder="Datum podpisa pogodbe" :language="slSI" :format="customDateFormatter"
                                  v-model="companyData.contract_signed_date"></datepicker>
                    </div>

                    <div>
                      <label class="vs-input--label">Datum veljavnosti pogodbe</label>
                      <datepicker placeholder="Datum veljavnosti pogodbe" :language="slSI" :format="customDateFormatter"
                                 v-model="companyData.contract_expiry_date"></datepicker>
                    </div>

                  </div>

                  <div class="grid grid-cols-1 mt-5">
                    <label for="active" class="vs-input--label mb-2">Sodelovanje s podjetjem je aktivno</label>
                    <vs-switch class="ml-1" color="success" v-model="companyData.active" id="active">
                      <span slot="on">DA</span>
                      <span slot="off">NE</span>
                    </vs-switch>
                  </div>

                </div>
              </div>
            </div>


            <div class="flex mt-5">
              <div class="w-full">
                <label class="vs-input--label">Opombe o podjetju</label>
                <vs-textarea class="w-full" rows="6" v-model="companyData.notes" />
              </div>
            </div>


          </div>

        </div>
        <div class="sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
          <template>

            <div class="vx-card mt-0" style="overflow: hidden">
              <div class="vx-card__header">
                <h4 class="text-success mb-4">Logotip podjetja</h4>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body p-20">
                  <img alt="logotip-podjetja" v-if="companyData.donor_logo" class="responsive rounded-lg" :src="$globalFunctions.getImage(companyData.donor_logo)">

<!--                  <vs-upload class="m-0 col-mx-auto" ref="logoUpload" :text="companyData.donor_logo ? 'Zamenjaj logotip' : 'Naloži logotip'" limit="1" automatic :action="$config.api.stageNew + 'donors/upload-logo'" :headers="{'Authorization': getToken}" fileName="files" @on-success="successUpload" />-->

                  <input type="file" ref="file" @change="fileChanged" accept="image/jpeg, image/png, image/gif" />
                </div>
              </div>
            </div>

            <div class="vx-card mt-10" style="overflow: hidden">
              <div class="vx-card__header">
                <h4 class="text-success mb-4">Lokacija podjetja</h4>
              </div>
              <div class="vx-card__collapsible-content">
                <div class="vx-card__body p-0">

                  <gmap-map ref="map" :center="center" :zoom="15" style="width: 100%; height: 500px">

                    <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false"/>
                    <GmapMarker
                      :key="index"
                      v-for="(item, index) in markers"
                      :position="item.position"
                      :clickable="true"
                      :draggable="false"
                      @click="toggleInfoWindow(item, index)"
                    />

                    <DirectionsRenderer/>



                  </gmap-map>

                </div>
              </div>
            </div>

          </template>
        </div>
      </div>


      <div class="flex mt-10 mb-20">
        <div class="w-1/2">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
            <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
          </vs-col>
        </div>
        <div class="w-1/2">
          <div class="flex">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
              <vs-button size="large" color="danger" icon="delete">Izbriši</vs-button>
            </vs-col>

            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
              <vs-button size="large" color="success" icon="save" @click="saveCompany">Shrani</vs-button>
            </vs-col>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import Vue from 'vue';
  import DirectionsRenderer from "../../../helpers/DirectionsRenderer";
  import vSelect from "vue-select";
  import Fuse from 'fuse.js'
  import Datepicker from 'vuejs-datepicker';
  import {slSI} from 'vuejs-datepicker/dist/locale';
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';

  let directionsService = null;
  let directionsDisplay = null;
  let geoCoder = null;

  export default {
    name: 'companyEdit',

    components: {
      DirectionsRenderer,
      'v-select': vSelect,
      'datepicker': Datepicker,
      'flat-pickr': flatPickr,
    },
    props: {
      companyId: Number
    },
    data() {
      return {
        slSI: slSI,

        center: {lat: 46.0569, lng: 14.5058},

        logo: null,

        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,

        infoOptions: {
          content: '',
          pixelOffset: {
            width: 0,
            height: -35
          }
        },

        markers: [],

        donorTypes: [],
        donorTypeId: null,

        companyData: {
          id: null,
          address: {},
          address_id: null,
          company_display_name: null,
          company_name: null,
          company_vat: null,
          donor_id_number: null,
          donor_bank_account: null,
          contact_person: null,
          contract_expiry_date: null,
          contract_sent_date: null,
          contract_signed_date: null,
          created_at: null,
          deleted: 0,
          disabled: 0,
          donation_description: null,
          donation_value: null,
          donorType: {},
          donor_logo: null,
          donor_type_id: 1,
          notes: null,
          updated_at: null
        }
      }
    },

    computed: {
      getToken() {
        const token = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).token : null;
        if (token) {
          return token
        }
      }
    },

    mounted() {
      const _this = this;



      console.log("collapse", _this.$refs.collapsible);

      _this.checkIfGoogleMapsApiLoaded().then((res) => {
        console.log("DONE");

        if (_this.companyId) {
          _this.$vs.loading();
          _this.getDonorTypes().then(() => {
            _this.getCompany();
          });
        } else {
          console.log("kaj pa zdej!?!");
          _this.getDonorTypes();
          _this.$vs.loading.close();
        }
      });
    },

    watch: {
      companyId: {
        handler(val, oldVal) {
          console.log("CHANGE!!!!");

          if (val) {
            this.getCompany();
          }
        }
      }
    },

    methods: {

      fileChanged() {
        const _this = this;

        _this.$vs.loading();

        let file = _this.$refs.file;
        console.log(file.files[0]);

        let formData = new FormData();
        formData.append('files', file.files[0]);

        Vue.prototype.$http.post(Vue.prototype.$config.api.productionNew + 'donors/upload-logo', formData)
          .then((res) => {

           console.log("RES!!!!!", res)
            _this.companyData.donor_logo = res.data.data;

            _this.$vs.loading.close();
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });


      },


      async checkIfGoogleMapsApiLoaded() {
        const _this = this;

        let promise = new Promise(function(resolve, reject) {
          if(typeof google !== 'undefined') {
            resolve("done")
          } else {
            setTimeout(()=> { _this.checkIfGoogleMapsApiLoaded() }, 400);
          }
        });
      },

      async getDonorTypes() {
        const _this = this;
        _this.$vs.loading();

        await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'donors/types')
          .then((res) => {

            _this.donorTypes = res.data.data;

            console.log("donorTypes: ", _this.donorTypes);

            _this.$vs.loading.close();
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });

      },


      getCompany() {
        const _this = this;

        Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'donors/' + _this.companyId)
          .then((res) => {

            _this.companyData = res.data.data; //_.filter(res.data.data, {id: _this.companyId})[0];
            _this.donorTypeId = res.data.data.donor_type_id;
            console.log("TOLE: ", _this.companyData);

            _this.showCompanyMarker();
            _this.$vs.loading.close();


          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      setMapsDirections() {
        const _this = this;

        if (!directionsService) {
          directionsService = new google.maps.DirectionsService;
          directionsDisplay = new google.maps.DirectionsRenderer;
          directionsDisplay.setMap(_this.$refs.map.$mapObject);
        }
      },


      getAddressData(addressData, addressSection) {
        const _this = this;

        console.log("addressData", addressData);

        document.getElementById(addressSection + "Address").value = addressData.formatted_address;
        _this.companyData.address.quick_search = addressData.formatted_address;
        _this.companyData.address.route = addressData.address_components[1].long_name;
        _this.companyData.address.street_number = addressData.address_components[0].long_name;
        _this.companyData.address.postal_town = addressData.address_components[2].long_name;
        _this.companyData.address.postal_code = addressData.address_components[5].long_name;
        _this.companyData.address.municipality = addressData.address_components[3].long_name;
        _this.companyData.address.country = addressData.address_components[4].long_name;


        if (!directionsService) {
          directionsService = new google.maps.DirectionsService;
          directionsDisplay = new google.maps.DirectionsRenderer;
          directionsDisplay.setMap(_this.$refs.map.$mapObject);
        }

        let position = {
          'lat': addressData.geometry.location.lat(),
          'lng': addressData.geometry.location.lng()
        };

        _this.markers[0] ={
          infoText: _this.companyData.company_name ? _this.companyData.company_name : 'Podjetje',
          position: position
        };
        _this.center = position;

        _this.$forceUpdate();

        _this.setMapsDirections();

      },

      showCompanyMarker() {
        const _this = this;

        geoCoder = new google.maps.Geocoder();
        geoCoder.geocode({'address': _this.companyData.address.quick_search}, function (results, status) {
          if (status === 'OK') {
            _this.center = results[0].geometry.location;

            let position = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            }

            _this.markers.push({
              infoText: _this.companyData.company_name ? _this.companyData.company_name : 'Podjetje',
              position: position
            });

            _this.setMapsDirections();

          } else {
            console.error('Geocode was not successful for the following reason: ' + status);
          }
        });

      },

      customDateFormatter(date) {
        return this.moment(date).format('DD. MM. YYYY');
      },

      saveCompany() {
        const _this = this;

        let company = _.omit(_this.companyData, ['campaigns', 'donorType', 'disabled', 'deleted', 'created_at', 'updated_at']);
        company.addressObject = company.address;
        company.donor_type_id = _this.donorTypeId;
        // company.date = _this.moment().valueOf();

        _this.$vs.loading();

        console.log("company data to save", company);

        Vue.prototype.$http.post(Vue.prototype.$config.api.productionNew + 'donors', company)
          .then((res) => {

            console.log("res", res);
            _this.$vs.loading.close();
            _this.$router.push({name: 'Podjetja'});

          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });

      },

      toggleInfoWindow: function(marker, idx) {
        this.infoWindowPos = marker.position;
        this.infoOptions.content = marker.infoText;

        if (this.currentMidx == idx) {
          this.infoWinOpen = !this.infoWinOpen;
        }
        else {
          this.infoWinOpen = true;
          this.currentMidx = idx;

        }
      },

      successUpload(res){
        const _this = this;
        console.log("res", res);
        let response = JSON.parse(res.currentTarget.response);

        console.log("response", response);
        if(response && !response.error) {
          _this.companyData.donor_logo = response.data;
        }

        this.$vs.notify({color:'success',title:'Upload Success',text:'Lorem ipsum dolor sit amet, consectetur'})
      }

    }
  }
</script>

