<template>
    <div id="companyContainer">
      <vs-breadcrumb class="mb-5">
        <li><router-link to="/">Domov</router-link><span class="vs-breadcrum--separator">/</span></li>
        <li aria-disabled="page" class="disabled-link">Donacije<span class="vs-breadcrum--separator">/</span></li>
        <li><router-link to="/donacije/podjetja">Donatorska podjetja</router-link><span class="vs-breadcrum--separator">/</span></li>
        <li aria-current="page" class="active">{{companyId ? 'Urejanje' : 'dodajanje'}} donatorskega podjetja</li>
      </vs-breadcrumb>

      <CompanyEdit :companyId="companyId"></CompanyEdit>
    </div>
</template>

<script>
  import Vue from 'vue';
  import CompanyEdit from "../../components/Sopotniki/donations/CompanyEdit";

  export default {
    name: 'company-donations-edit',

    components: {
      CompanyEdit
    },

    data() {
      return {
        companyId: this.$route.params.companyId ? parseInt(this.$route.params.companyId) : null,
      }
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.companyId = null;
      setTimeout(() => {next()}, 100);
    },

    computed: {},

    mounted() {
      const _this = this;
    },

    methods: {}
  }
</script>

